
import Component, { mixins } from 'vue-class-component'

import { Vue } from 'vue-property-decorator'

import { getModule } from 'vuex-module-decorators'
import Translations from '@/store/modules/translations'

const translations = getModule(Translations) // Store accessor.

const ADD_BUTTON_KEY = 'doneAddMoreText'
const CONTACT_KEY = 'doneContactText'
const DONE_KEY = 'doneAllDoneText'
const THANKS_KEY = 'doneThankYouText'
const CONTACT_EMAIL_KEY = 'contactEmail'

const ThankYouProps = Vue.extend({
  props: {
  }
})

@Component
export default class ThankYou extends mixins(ThankYouProps, Vue) {
  thanksText = ''
  contactSkyText = ''
  doneText = ''
  addButtonText = ''

  email = ''
  emailHref = ''

  mounted () {
    this.thanksText = translations.getTranslations(THANKS_KEY)
    this.contactSkyText = translations.getTranslations(CONTACT_KEY)
    this.doneText = translations.getTranslations(DONE_KEY)
    this.addButtonText = translations.getTranslations(ADD_BUTTON_KEY)
    this.email = translations.getTranslations(CONTACT_EMAIL_KEY)

    this.emailHref = `mailto:${this.email}`
  }

  handleAddMoreRecordingsClicked () {
    console.debug('handleAddMoreRecordingsClicked')

    this.$router.push({ path: '/' })
  }
}
